import Link from 'next/link'
import {ctaEvent} from "../../../utils/events";

export default function Footer() {

    return (
        <>
            <div className="footer-widget">
                <div className="container">
                    <div className="row text">
                        <div className="col-md-12 d-md-flex justify-content-center">
                            <h1>To get started, simply click the button!</h1>
                        </div>
                        <div className="col-md-12 d-md-flex justify-content-center">
                            <Link href="/submit-extension" prefetch={false} onClick={()=>ctaEvent("btf-cta")}>
                                <a className="btn btn-default cta-btn" id="btf-cta" href="/submit-extension" onClick={()=>ctaEvent("btf-cta")}>Submit Tax
                                    Extension</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
